import mixitup from "mixitup";
$(window).on("load", function () {
  $(window).on("scroll", function () {
    var services = document.querySelector(".services");
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      if(!($("#head").hasClass("hover"))){
          $("#head").addClass("scroll");
      };
      $("#nav-responsive").addClass("scroll");
      $(".elementRange").addClass('visible');
      if(services){
          let servicesPosition = $(".services").position().top;
          if (scroll > servicesPosition - 500) {
            $(".services").find(".bloc").addClass("visible");
          }
      }
      
    } else {
      $("#head").removeClass("scroll");
      $("#nav-responsive").removeClass("scroll");
      $(".elementRange").removeClass("visible");
      $(".services").find('.bloc').removeClass("visible");
    }
  });

  if(window.location.href.includes("#ajouter-avis")){
    $("html, body").animate(
      {
        scrollTop: $("#ajouter-avis").offset().top - 118,
      },
      10
    );
  };
  var scroll = $(window).scrollTop();
  if (scroll > 0) {
    $("#head").addClass("scroll");
    $("#nav-responsive").addClass("scroll");
  } else {
    $("#head").removeClass("scroll");
    $("#nav-responsive").removeClass("scroll");
  }

  $("#nav").find(".ranges").on("mouseenter", function () {
    $("#subnav").addClass("active");
  });
  $(".wrapper").on("mouseleave", function () {
    $("#subnav").removeClass("active");
  });
  $(".elementRange").on("mouseenter", function () {
    $(this).addClass("visited");
  });
  $(".elementRange").on("mouseleave", function () {
    $(this).removeClass("visited");
  });
  $(".elementRange").on("click", function(){
    if(!$(this).hasClass('childrens') && !$(this).hasClass('module')){
        window.location.href = $(this).find(".hover").find("a").attr("href");
    }
    
  });

  $("#add_notice").on("click", function (event) {
    $("#myModalNoticesForm").show();
  });
  $(".notices_form").on("submit", function (event) {
    event.stopPropagation();
    event.preventDefault();
  });

  // var span = document.getElementsByClassName("close")[0];
  // span.onclick = function () {
  //   modal.style.display = "none";
  // };
  $("#btn-menu").on("click", function () {
    toggleActiveBtn();
  });
  var toggleActiveBtn = function toggleActiveBtn() {
    if ($("#btn-menu").hasClass("active")) {
      $("#btn-menu").removeClass("active");
      $("#hide-nav").css("display", "none");
      
      if (scroll == 0) {
        $("#nav-responsive").removeClass("scroll");
      }
    } else {
      $("#btn-menu").addClass("active");
      $("#hide-nav").css("display", "flex");
      $("#nav-responsive").addClass("scroll");
    }
  };
  if ($(window).width() < 500) {
    $("#footer")
      .find(".title")
      .on("click", function () {
        if ($(this).parent().hasClass("open")) {
          $(this).parent().removeClass("open");
        } else {
          $(this).parent().addClass("open");
        }
      });
  }

  $(".img-modal").on('click', function(){
      var modal = $("#myModal");
      $(modal).addClass('visible');
      $(".contain-modal").find('img').attr('src', $(this).find('img').attr('src'));
      $(".contain-modal").find('.label').html($(this).find('.img-label').html());
      var span = document.getElementsByClassName("close")[0];
      span.onclick = function () {
        $(modal).removeClass('visible');
      };
  });

  var mixImplementations = document.querySelector(".rangeImplementations > .gallery");
  if(mixImplementations){
      var mixer = mixitup(".rangeImplementations > .gallery");
      $(".more-implementations > .container > span").on("click", function () {
        if (
          $(".rangeImplementations")
            .find(".gallery")
            .find(".images")
            .hasClass("expand")
        ) {
          $(".rangeImplementations")
            .find(".gallery")
            .find(".images")
            .removeClass("expand");
          $(".more-implementations > .container > span").text(
            "Visualiser plus de réalisations"
          );
        } else {
          $(".rangeImplementations")
            .find(".gallery")
            .find(".images")
            .addClass("expand");
          $(".more-implementations > .container > span").text("Réduire");
        }
      });
  }

});
