$(window).on('load', function(){
    $(".newsletters_form").on("submit", function (event) {
      event.stopPropagation();
      event.preventDefault();
      let url = "/newsletters/add";
      var formSerialize = $(this).serialize();

      $.ajax({
        type: "POST",
        url: url,
        data: formSerialize,
        dataType: "json",
        encode: true,
        success: function (response) {
          showFormAlert($(".newsletters_form"), "success", response);
          $(".newsletters_form")[0].reset();
        },
        error: function (error) {
          if (Array.isArray(error.responseJSON)) {
            $(".newsletters_form")
              .find(".invalid-feedback-input")
              .append(error.responseJSON[0].message);
          } else {
            showFormAlert($(".newsletters_form"), "error", error);
          }
        },
      });
    });
    $(".estimate_form").on("submit", function (event) {
      console.log("test");  
      event.stopPropagation();
      event.preventDefault();
      let url = $(this).attr("action");
      $.ajax({
        type: "POST",
        url: url,
        data: $(this).serialize(),
        dataType: "json",
        encode: true,
        success: function (response) {
          hideFormErrors($(".estimate_form"));
          showFormAlert($(".estimate_form"), "success", response);
          $(".estimate_form")[0].reset();
        },
        error: function (error) {
          console.log(error);
          if (Array.isArray(error.responseJSON)) {
            showFormErrors($(".estimate_form"), error);
          } else {
            showFormAlert($(".estimate_form"), "danger", error.responseJSON);
          }
        },
      });
    });
    $(".notices_form").on("submit", function (event) {
      event.stopPropagation();
      event.preventDefault();
      let url = $(this).attr("action");
      $.ajax({
        type: "POST",
        url: url,
        data: $(this).serialize(),
        dataType: "json",
        encode: true,
        success: function (response) {
          hideFormErrors($(".notices_form"));
          showFormAlert($(".notices_form"), "success", response);
          setTimeout(function () {
            window.location.href = "/avis";
          }, 2000);
        },
        error: function (error) {
          if (Array.isArray(error.responseJSON)) {
            showFormErrors($(".notices_form"), error);
          } else {
            showFormAlert($(".notices_form"), "error", error);
          }
        },
      });
    });
    $(".contact_form").on('submit', function(event){
      event.stopPropagation();
      event.preventDefault();
      let url = $(this).attr("action");
      $.ajax({
        type: "POST",
        url: url,
        data: $(this).serialize(),
        dataType: "json",
        encode: true,
        success: function (response) {
          hideFormErrors($(".contact_form"));
          showFormAlert($(".contact_form"), "success", response);
          $(".contact_form")[0].reset();
        },
        error: function (error) {
          if (Array.isArray(error.responseJSON)) {
            showFormErrors($(".contact_form"), error);
          } else {
            showFormAlert($(".contact_form"), "danger", error.responseJSON);
          }
        },
      });
    });
});

function showFormErrors(form, error) {
  form
    .find(".form-group." + error.responseJSON[0].property)
    .find(".invalid-feedback-input")
    .append(error.responseJSON[0].message);
  form
    .find(".form-group." + error.responseJSON[0].property)
    .find("input")
    .addClass("invalid");
}
function hideFormErrors(form) {
  form.find(".form-group").find(".invalid-feedback-input").append("");
  form.find(".form-group").find("input").addClass("invalid");
}
function showFormAlert(form, type, alert) {
  form.find(".alert-" + type).append("");
  form.find(".alert-" + type).append(alert);
  form.find(".alert-" + type).show();
}