$(document).on("load", function () {
    tinymce.init({
      selector: "textarea", // change this value according to your HTML
      plugins: 'table',
      toolbar: 'undo redo spellcheckdialog formatpainter | fontfamily fontsize | bold italic underline forecolor backcolor | link image | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat',
      a_plugin_option: true,
      a_configuration_option: 400,
      force_br_newlines: true,
      force_p_newlines: true,
      newline_behavior: 'block'
    });
});

