require("@fortawesome/fontawesome-free/css/all.min.css");
require("@fortawesome/fontawesome-free/js/all.js");

import "./styles/init/init.scss";
import "./styles/init/font.scss";
import "./styles/wait.scss";
import "./styles/nav.scss";
import "./styles/home.scss";
import "./styles/range.scss";
import "./styles/notices.scss";
import "./styles/contact.scss";
import "./styles/legalNotices.scss";
import "./styles/professionnel.scss";
import "./styles/about.scss";
import "./styles/estimate.scss";
import "./styles/maintenance.scss";
import "./styles/footer.scss";
import "./styles/modules/homeSlider.scss";
import "./styles/modules/homeRange.scss";
import "./styles/modules/homeLink.scss";
import "./styles/modules/form.scss";
import "./styles/modules/otherRanges.scss";
import "./styles/modules/partners.scss";
import "./styles/modules/footerPlan.scss";
import "./styles/modules/services.scss";
import "./styles/modules/notices.scss";
import "./styles/modules/ranges.scss";
import "./styles/modules/expertises.scss";

import Glider from "glider-js";
import mixitup from "mixitup";

import "./javascripts/form.js";
import "./javascripts/slides.js";
import "./javascripts/site.js";
import "./javascripts/tinymce.js";
import "./javascripts/jquery.js";