import Glide from "@glidejs/glide";
import { gliderAutoplay } from "glider-autoplay";
$(window).on("load", function () {
  var gliderPartners = document.querySelector(".gliderPartners");
  var gliderNews = document.querySelector(".glider-news");
  var gliderNotices = document.querySelector(".gliderNotices");
  var gliderModuleNotices = document.querySelector(".gliderModulesNotices");
  var homeSlider = document.querySelector(".homeslider");

  if(homeSlider){
    gliderAutoplay(new Glider(homeSlider, {
      slidesToShow: 1,
      dots: ".dots-homeslider-notices",
      draggable: false,
    }), {
      interval: 5000, // int, required
      pausable: true
    });
  }

  if(gliderNotices){
      var slideNotices = new Glider(gliderNotices, {
        slidesToShow: 1,
        dots: ".dots-notices",
        draggable: true,
      });
      $(".numberPages").first().addClass("active");
      $(".numberPages").on('click', function(){
        $(".numberPages").removeClass('active');
        slideNotices.scrollItem($(this).data("page"));
        $(this).addClass('active');
        $("html, body").animate(
          {
            scrollTop: $(".glider-track").offset().top - 118,
          },
          1000
        );
      })
  }

  if(gliderModuleNotices){
    new Glider(gliderModuleNotices, {
      slidesToShow: 1,
      dots: ".dots-module-notices",
      draggable: true,
    });
  }

  if(gliderNews){
      new Glider(gliderNews, {
        slidesToShow: 1,
        dots: ".dots",
        draggable: true,
      });
  }


  if(gliderPartners !== null){
      new Glider(document.querySelector(".gliderPartners"), {
        slidesToShow: 3,
        dots: ".dots-partners",
        slidesToScroll: 1,
        arrows: {
          prev: ".glider-prev",
          next: ".glider-next",
        },
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      });
  }
});
